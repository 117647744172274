<template>
  <input
    v-model="val"
    v-mask="['######']"
    class="form-control"
    :class="{'valid': val}"
  />
</template>

<script>
export default {
  name: 'CodeValidate',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    val: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)

        if (/[0-9]{6}/.test(value)) {
          this.$emit('fulfilled', value)
        }
      }
    }
  },
}
</script>
