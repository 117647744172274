<template>
  <div class="code-send-input">
    <input type="text"
           :class="inputClass"
           v-model.trim="val"
           :disabled="isCodeSent"
           @input="$emit('validate')"
           @keyup.enter="makeAction"
    />
    <transition name="fade">
      <button v-if="canShowBtn"
              class="default-button"
              v-html="currBtnState.label()"
              :disabled="currBtnState.disabled || hasError"
              @click.prevent.stop="makeAction"
      />
    </transition>
    <icon-check class="check-icon" v-if="completed"/>
  </div>
</template>

<script>

import IconCheck from '@/libs/ConfirmationInput/IconCheck'

export default {
  name: 'CodeSend',
  components: {IconCheck},
  props: {
    value: {
      type: String
    },
    inputClass: {
      type: String,
      default: 'form-control valid'
    },
    timeout: {
      type: Number,
      default: 60
    },
    type: {
      type: String,
      required: true,
      validator: (val) => {
        return ['phone', 'email'].includes(val)
      }
    },
    hasError: {
      type: Boolean,
      default: false
    },
    canShowBtn: {
      type: Boolean,
      default: false
    },
    completed: {
      type: Boolean,
      default: false
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    isValidating: {
      type: Boolean,
      default: false
    },
    canSkipCode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      seconds: 0,
      timeoutId: null,
      btnStateIndex: 0,
      btnStates: [
        {
          label: () => 'Send code',
          disabled: false
        },
        {
          label: () => `${this.seconds} sec`,
          disabled: true
        },
        {
          label: () => 'Resend code',
          disabled: false
        },
        {
          label: () => 'Save',
          disabled: false
        }
      ]
    }
  },
  watch: {
    isProcessing(newVal) {
      if (!newVal) this.resetTimeout(0)
    },
    canSkipCode(newVal) {
      this.btnStateIndex = newVal ? 3 : 0
    }
  },
  computed: {
    val: {
      set(val) {
        this.$emit('input', val)
      },
      get() {
        return this.value
      }
    },
    currBtnState() {
      return this.btnStates[this.btnStateIndex]
    },
    isCodeSent() {
      return this.btnStateIndex === 1
    }
  },

  methods: {
    makeAction() {
      if (!this.isValidating) {
        return this.canSkipCode
            ? this.saveConfirmed()
            : this.sendCode()
      }
    },
    sendCode() {
      if (this.canShowBtn && !this.currBtnState.disabled) {
        this.$sdk.code.send(this.type, {recipient: this.value}, 0).then((response) => {
          if (response.data && response.data.recipient && !response.data.recipient[0].includes('confirm')) {
            this.$notice.error(`<span>${response.data.recipient[0]}</span>`)
          } else {
            this.btnStateIndex = 1
            this.createTimeout(2)
            this.showNotification()
            this.$emit('sent')
          }
        }).catch(() => {
          this.$notice.error('Something was wrong. Please reload page and try again')
        })
      }
    },
    saveConfirmed() {
      this.$emit('submit')
    },
    showNotification() {
      this.$notice.success(`<span>Code has been sent</span>`)
    },
    createTimeout(status) {
      this.seconds = this.timeout
      const interval = setInterval(() => {
        this.timeoutId = interval
        this.seconds--

        if (this.seconds === 1) {
          this.resetTimeout(status)
          this.$emit('reset')
        }
      }, 1000)
    },
    resetTimeout(status) {
      clearInterval(this.timeoutId)
      this.timeoutId = null
      this.btnStateIndex = status
      this.seconds = 0
    }
  }
}
</script>

<style lang="less" scoped>
.code-send-input {
  display: flex;

  input {
    &:disabled {
      border: none;
      background-color: rgba(255, 255, 255, 0.05);
      color: #FFFFFF;
    }
  }

  .check-icon,
  .default-button {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 5px 15px;
  }

  .default-button {
    width: fit-content;
    min-width: 76px;
    line-height: unset;

    &:disabled {
      background-color: #19191C;
      color: #FFFFFF;
      border-color: transparent;
    }
  }

  .check-icon {
    bottom: 7px;
    padding: 0;
  }
}
</style>
