<template>
  <div class="page" v-if="profile">
    <div class="panel-wrapper">
      <h1 class="headline">
        {{ getHeadline }}
      </h1>
      <h2 class="subline">
        To change {{ type }} please validate new {{ type }}.
      </h2>
    </div>
    <div class="page-forms main-content-grid">
      <div  class="grid">
        <div class="col-6">
          <form-group :label="label" :error="errors[this.type]">
            <code-send
              :type="type"
              :has-error="!!(errors[this.type])"
              :completed="completed"
              :can-show-btn="canShowButton"
              :can-skip-code="canSkipCode"
              :is-processing="isCodeSent"
              :is-validating="isValidating"
              v-model="form.recipient"
              @validate="isValidating = true; validate([type])"
              @sent="sendCode"
              @reset="resetCode"
              @submit="submit"
            />
          </form-group>
        </div>
        <template v-if="isCodeSent && !completed && !canSkipCode">
          <div class="col-6">
            <form-group label="Paste code" :error="errors.code">
              <code-validate
                v-model="form.code"
                @fulfilled="validate(['code'])"
              />
            </form-group>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CodeSend from '@/libs/ConfirmationInput/CodeSend'
import CodeValidate from '@/libs/ConfirmationInput/CodeValidate'

import FormGroup from '@/libs/forms/FormGroup'
import {mapGetters} from 'vuex'
import debounce from 'lodash/debounce'

const DATA_IS_NOT_VERIFIED = 'DATA_IS_NOT_VERIFIED'

export default {
  components: {
    FormGroup,
    CodeSend,
    CodeValidate
  },
  props: {
    type: {
      type: String,
      default: 'email',
      validator: (val) => {
        return ['phone', 'email'].includes(val)
      }
    }
  },
  data () {
    return {
      attributes: [this.type, 'code'],
      form: {
        recipient: null,
        code: null
      },
      errors: {},
      isCodeSent: false,
      completed: false,
      canSkipCode: false,
      isValidating: false
    }
  },
  mounted() {
    this.form.recipient = this.profile[this.type]
  },
  computed: {
    ...mapGetters(['profile']),
    canShowButton () {
      return !this.isSameData
          && !this.errors[this.type]
          && !this.completed
          && !this.isInitializing
          && !this.isValidating
    },
    isSameData () {
      return this.form.recipient === this.profile[this.type]
    },
    isInitializing () {
      return this.form.recipient === null
    },
    getHeadline () {
      return `Change ${this.type === 'email' ? 'email' : 'phone number'}`
    },
    label () {
      return this.type === 'email' ? 'Email' : 'Phone'
    },
    formData () {
      return {[this.type]: this.form.recipient}
    }
  },
  methods: {
    validate: debounce(async function (attributes = []) {
      const [updateValidation, confirmValidation] = await Promise.all([
          this.$sdk.user.update(this.formData, 1),
          this.$sdk.code.confirm(this.type, this.form, 0)
      ])

      if (this.isResponseEmpty(updateValidation.data)) {
        this.errors = {}
        this.canSkipCode = true
      } else {
        this.canSkipCode = false

        if (!this.isCodeSent) {
          this.checkErrors(attributes, updateValidation.data)
        } else {
          this.checkErrors(attributes, confirmValidation.data)
          if (!Object.keys(this.errors).length) this.submit()
        }
      }

      this.isValidating = false
    }, 500),

    submit: debounce(async function () {
      this.$sdk.user.update(this.formData, 0).then(response => {
        if (this.isResponseEmpty(response.data)) {
          this.completed = true
          setTimeout(() => {
            this.$notice.success(`Your ${this.type} has been successfully changed`)
            this.resetData()
          }, 1500)
        } else {
          this.$notice.error(`<span>${response.data[this.type][0]}</span>`)
          this.resetData()
        }
      })
    }, 300),

    checkErrors(attributes, data) {
      for (const attr of attributes) {
        if (data[attr] !== undefined && data[attr][0] !== DATA_IS_NOT_VERIFIED) {
          this.$set(this.errors, attr, data[attr])
        } else {
          this.$delete(this.errors, attr)
        }
      }
    },
    isResponseEmpty(data) {
      return Array.isArray(data) && !data.length
    },
    sendCode () {
      this.isCodeSent = true
    },
    resetCode () {
      this.form.code = null
    },
    resetData () {
      this.form.recipient = this.profile[this.type]
      this.completed = false
      this.isCodeSent = false
      this.canSkipCode = false
      this.resetCode()
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;

  .panel-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  &-forms {
    @media (max-width: 992px) {
      .grid .col-6 {
        grid-column: span 12;
      }
    }
  }
}
</style>
